import React from 'react'
import useTranslation from '@/i18n'
import { getCurrentLang } from '@/utils'

function Header() {
  const { t } = useTranslation()

  const lang = getCurrentLang()

  // Hirano-san requests. When Japanese is selected, last name should be first.
  if (lang === 'ja') {
    return (
      <tr>
        <th
          scope="col"
          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          style={{ width: '300px' }}
        >
          {t('HANDLE')}
        </th>
        <th
          scope="col"
          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-32"
        >
          {t('Last name')}
        </th>
        <th
          scope="col"
          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-32"
        >
          {t('First name')}
        </th>
        <th
          scope="col"
          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-32"
        >
          {t('Last name (En)')}
        </th>
        <th
          scope="col"
          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-32"
        >
          {t('First name (En)')}
        </th>
        <th
          scope="col"
          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-32"
        >
          {t('Email')}
        </th>
        <th
          scope="col"
          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-32"
        >
          {t('Created at')} (UTC)
        </th>
        <th
          scope="col"
          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-32"
        >
          {t('Updated at')} (UTC)
        </th>
      </tr>
    )
  }

  return (
    <tr>
      <th
        scope="col"
        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
        style={{ width: '300px' }}
      >
        {t('HANDLE')}
      </th>
      <th
        scope="col"
        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-32"
      >
        {t('First name')}
      </th>
      <th
        scope="col"
        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-32"
      >
        {t('Last name')}
      </th>
      <th
        scope="col"
        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-32"
      >
        {t('First name (En)')}
      </th>
      <th
        scope="col"
        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-32"
      >
        {t('Last name (En)')}
      </th>
      <th
        scope="col"
        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-32"
      >
        {t('Email')}
      </th>
      <th
        scope="col"
        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-32"
      >
        {t('Created at')} (UTC)
      </th>
      <th
        scope="col"
        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-32"
      >
        {t('Updated at')} (UTC)
      </th>
    </tr>
  )
}

export default Header
