import { axios, getAuthorization } from '@/api/axios'
import { withRefreshToken } from '@/auth'
import { errConverter } from '../error'
import { ContactAttribute } from '@/types'

const getContactListGtld = (): Promise<ContactAttribute[]> =>
  new Promise<ContactAttribute[]>((resolve, reject) =>
    axios
      .get<ContactAttribute[]>(`/contact-list/attribute`, {
        headers: getAuthorization(),
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(errConverter(err))
      })
  )

const get = withRefreshToken(getContactListGtld)

export { get }
