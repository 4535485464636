import { axios, getAuthorization } from '@/api/axios'
import { withRefreshToken } from '@/auth'
import { errConverter } from '../error'
import { ApiContactAttributeParams } from '@/types'

const patchAttributeRegistrant = (
  params?: ApiContactAttributeParams
): Promise<void> =>
  new Promise<void>((resolve, reject) =>
    axios
      .patch<void>('/attribute/contact', params, {
        headers: getAuthorization(),
      })
      .then(() => resolve())
      .catch((err) => reject(errConverter(err)))
  )

const patch = withRefreshToken(patchAttributeRegistrant)

export { patch }
