import React, { useEffect, useRef } from 'react'
import useTranslation from '@/i18n'
import { ContactAttribute } from '@/types'
import {
  useProgress,
  useSafeState,
  useErr,
  useTooltip,
  useRightBar,
  useHeight,
} from '@/hooks'
import { patch } from '@/api/attribute/contact'
import Inputs from './Inputs'
import ButtonRounded from '@/components/Parts/ButtonRounded'
import clonedeep from 'lodash.clonedeep'
import {
  clean,
  defaultAttributeContact,
  defaultAttributeContactInvalids,
} from '@/utils'
import { formatErrorTable } from '@/api/error'

type Props = {
  contact: ContactAttribute
  load: () => Promise<void>
}

function Editor(props: Props) {
  const ref = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()
  const { start, stop } = useProgress()
  const { height } = useHeight()
  const [editorH, setEditorH] = useSafeState<undefined | string>(undefined)
  const [contactTemp, setContactTemp] = useSafeState<ContactAttribute>(
    clonedeep(defaultAttributeContact)
  )
  const [invalids, setInvalids] = useSafeState(
    clonedeep(defaultAttributeContactInvalids)
  )
  const { setErr } = useErr()
  const { setTooltip } = useTooltip()

  const [disable, setDisable] = useSafeState(true)
  const { closeRightBar } = useRightBar()

  useEffect(() => {
    if (window != null && ref != null && ref.current != null) {
      const rect = ref.current.getBoundingClientRect()
      setEditorH(`${height - rect.top - 60}px`)
    }
  }, [height])

  useEffect(() => {
    const {
      TransactionID,
      CreateTime,
      LastUpdateTime,
      ContactReplyMail,
      ...rest
    } = props.contact

    if (ContactReplyMail != null) {
      ContactReplyMail.map(
        (mail, index) => (contactTemp.ContactReplyMail![index] = mail)
      )
    }

    const res = clean({ ...rest })

    setContactTemp(
      clonedeep({ ...res, ContactReplyMail: [...contactTemp.ContactReplyMail] })
    )
  }, [])

  useEffect(() => {
    setDisable(
      contactTemp.ContactAddress === '' ||
        contactTemp.ContactPostalCode === '' ||
        contactTemp.ContactAddressEn === '' ||
        contactTemp.ContactEMailAddress === '' ||
        contactTemp.ContactPhoneNumber === '' ||
        contactTemp.ContactFirstName === '' ||
        contactTemp.ContactOrganizationName === '' ||
        contactTemp.ContactOrganizationNameEn === ''
    )
  }, [contactTemp])

  const save = () => {
    const {
      ContactReplyMail,
      ContactLastName,
      ContactFirstName,
      ContactLastNameEn,
      ContactFirstNameEn,
      ...rest
    } = contactTemp

    const mails = ContactReplyMail.filter((mail) => mail.length > 0)
    const res = mails.length > 0 ? mails : ['']

    setInvalids(clonedeep(defaultAttributeContactInvalids))

    start(0)
    patch({ ContactReplyMail: res, ...rest })
      .then(() => {
        props
          .load()
          .then(() => {
            stop()
            setTooltip('Completed')
            closeRightBar()
          })
          .catch((err) => {
            setErr(err.message, err.status)
            stop()
          })
      })
      .catch((err) => {
        if (err.status === 422 && err.subCode === 16) {
          err.params.forEach(({ key, code, index }) => {
            invalids[key].message = formatErrorTable[code]
            invalids[key].params = index
          })
          setInvalids({ ...invalids })
          setErr('Invalid parameter', 422)
        } else {
          setErr(err.message, err.status)
        }
        stop()
      })
  }

  return (
    <>
      <div
        className="px-4 w-full sm:w-110 overflow-y-auto"
        style={{
          height: editorH,
        }}
        ref={ref}
      >
        <p className="text-blue-500 mt-4 text-sm font-medium ">{t('HANDLE')}</p>
        <p className="font-medium text-gray-800 mb-4">
          {props.contact.ContactHandle}
        </p>
        <Inputs
          contact={contactTemp}
          setContact={setContactTemp}
          invalids={invalids}
        />
      </div>
      <div className="text-right mt-2 mr-4">
        <ButtonRounded name={'Update'} onClick={save} disabled={disable} />
      </div>
    </>
  )
}

export default Editor
