import React, { useRef, useEffect } from 'react'
import { ContactAttribute } from '@/types'
import useTranslation from '@/i18n'
import { useRightBar } from '@/hooks'
import ContactEditor from './ContactEditor'
import { getCurrentLang } from '@/utils'

type Props = {
  contact: ContactAttribute
  load: () => Promise<void>
}

const Contact = ({ contact, load }: Props) => {
  const { d } = useTranslation()
  const { openRightBar, closeRightBar, isOpenRightBar } = useRightBar()
  const refIsOpenRightBar = useRef(isOpenRightBar)

  useEffect(() => {
    refIsOpenRightBar.current = isOpenRightBar
  }, [isOpenRightBar])

  const open = () => {
    closeRightBar()

    const intervalId = setInterval(() => {
      if (!refIsOpenRightBar.current) {
        clearInterval(intervalId)
        openRightBar(<ContactEditor contact={contact} load={load} />)
      }
    }, 50)
  }

  const lang = getCurrentLang()

  if (lang === 'ja') {
    return (
      <tr key={contact.ContactHandle}>
        <td className="px-6 py-4 break-words text-sm font-medium">
          <p
            className="cursor-pointer text-blue-500  hover:text-blue-300"
            onClick={open}
          >
            {contact.ContactHandle}
          </p>
        </td>
        <td className="px-6 py-4 text-sm text-gray-900 truncate whitespace-nowrap">
          {contact.ContactLastName}
        </td>
        <td className="px-6 py-4 text-sm text-gray-900 truncate whitespace-nowrap">
          {contact.ContactFirstName}
        </td>
        <td className="px-6 py-4 text-sm text-gray-900 truncate whitespace-nowrap">
          {contact.ContactLastNameEn}
        </td>
        <td className="px-6 py-4 text-sm text-gray-900 truncate whitespace-nowrap">
          {contact.ContactFirstNameEn}
        </td>
        <td className="px-6 py-4 text-sm text-gray-900 truncate whitespace-nowrap">
          {contact.ContactEMailAddress}
        </td>
        <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
          {d('ymd', new Date(contact.CreateTime!))}
          <br />
          {d('hms', new Date(contact.CreateTime!))}
        </td>
        <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
          {d('ymd', new Date(contact.LastUpdateTime!))}
          <br />
          {d('hms', new Date(contact.LastUpdateTime!))}
        </td>
      </tr>
    )
  }

  return (
    <tr key={contact.ContactHandle}>
      <td className="px-6 py-4 break-words text-sm font-medium">
        <p
          className="cursor-pointer text-blue-500  hover:text-blue-300"
          onClick={open}
        >
          {contact.ContactHandle}
        </p>
      </td>

      <td className="px-6 py-4 text-sm text-gray-900 truncate whitespace-nowrap">
        {contact.ContactFirstName}
      </td>
      <td className="px-6 py-4 text-sm text-gray-900 truncate whitespace-nowrap">
        {contact.ContactLastName}
      </td>
      <td className="px-6 py-4 text-sm text-gray-900 truncate whitespace-nowrap">
        {contact.ContactFirstNameEn}
      </td>
      <td className="px-6 py-4 text-sm text-gray-900 truncate whitespace-nowrap">
        {contact.ContactLastNameEn}
      </td>
      <td className="px-6 py-4 text-sm text-gray-900 truncate whitespace-nowrap">
        {contact.ContactEMailAddress}
      </td>

      <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
        {d('ymd', new Date(contact.CreateTime!))}
        <br />
        {d('hms', new Date(contact.CreateTime!))}
      </td>
      <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
        {d('ymd', new Date(contact.LastUpdateTime!))}
        <br />
        {d('hms', new Date(contact.LastUpdateTime!))}
      </td>
    </tr>
  )
}

export default Contact
