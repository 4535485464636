import React from 'react'
import UserInput from '@/components/Parts/UserInput'
import UserInputs from '@/components/Parts/UserInputs'
import { ContactAttribute, Invalids } from '@/types'

type Props = {
  contact: ContactAttribute
  setContact: (contact: ContactAttribute) => void
  invalids: Invalids
}

function Inputs({ contact, setContact, invalids }: Props) {
  const update = (key: string, value: string, res: boolean) => {
    contact[key] = value
    setContact({ ...contact })
  }

  const updateArray = (
    key: string,
    value: string,
    id: number,
    res: boolean
  ) => {
    contact[key][id] = value
    setContact({ ...contact })
  }

  return (
    <>
      <UserInput
        name="Last name"
        max={300}
        pattern=".{1,300}"
        invalid={invalids.ContactLastName}
        disabled={true}
        value={contact.ContactLastName}
        required={false}
        onChange={(value, res) => update('ContactLastName', value, res)}
      />

      <UserInput
        name="First name"
        max={300}
        pattern=".{1,300}"
        invalid={invalids.ContactFirstName}
        value={contact.ContactFirstName}
        required={false}
        disabled={true}
        onChange={(value, res) => update('ContactFirstName', value, res)}
      />

      <UserInput
        name="Last name (En)"
        max={300}
        pattern=".{1,300}"
        invalid={invalids.ContactFirstNameEn}
        value={contact.ContactLastNameEn}
        required={false}
        disabled={true}
        onChange={(value, res) => update('ContactLastNameEn', value, res)}
      />

      <UserInput
        name="First name (En)"
        max={300}
        pattern=".{1,300}"
        invalid={invalids.ContactFirstNameEn}
        value={contact.ContactFirstNameEn}
        required={false}
        disabled={true}
        onChange={(value, res) => update('ContactFirstNameEn', value, res)}
      />

      <UserInput
        name="Contact email address"
        max={300}
        pattern=".{1,300}"
        invalid={invalids.ContactEMailAddress}
        value={contact.ContactEMailAddress}
        required={true}
        onChange={(value, res) => update('ContactEMailAddress', value, res)}
      />

      <UserInput
        name="Contact organization name"
        max={300}
        pattern=".{1,300}"
        invalid={invalids.ContactOrganizationName}
        value={contact.ContactOrganizationName}
        required={true}
        onChange={(value, res) => update('ContactOrganizationName', value, res)}
      />

      <UserInput
        name="Contact organization name (En)"
        max={300}
        pattern=".{1,300}"
        invalid={invalids.ContactOrganizationNameEn}
        value={contact.ContactOrganizationNameEn}
        required={true}
        onChange={(value, res) =>
          update('ContactOrganizationNameEn', value, res)
        }
      />

      <UserInput
        name="Postal code"
        max={8}
        pattern=".{1,8}"
        invalid={invalids.ContactPostalCode}
        value={contact.ContactPostalCode}
        required={true}
        onChange={(value, res) => update('ContactPostalCode', value, res)}
      />

      <UserInput
        name="Contact address"
        max={300}
        pattern=".{1,300}"
        invalid={invalids.ContactAddress}
        value={contact.ContactAddress}
        required={true}
        onChange={(value, res) => update('ContactAddress', value, res)}
      />

      <UserInput
        name="Contact address (En)"
        max={300}
        pattern=".{1,300}"
        invalid={invalids.ContactAddressEn}
        value={contact.ContactAddressEn}
        required={true}
        onChange={(value, res) => update('ContactAddressEn', value, res)}
      />

      <UserInput
        name="Contact division"
        max={300}
        pattern=".{1,300}"
        invalid={invalids.ContactDivision}
        value={contact.ContactDivision}
        required={false}
        onChange={(value, res) => update('ContactDivision', value, res)}
      />

      <UserInput
        name="Contact division (En)"
        max={300}
        pattern=".{1,300}"
        invalid={invalids.ContactDivisionEn}
        value={contact.ContactDivisionEn}
        required={false}
        onChange={(value, res) => update('ContactDivisionEn', value, res)}
      />

      <UserInput
        name="Contact title"
        max={300}
        pattern=".{1,300}"
        invalid={invalids.ContactTitle}
        value={contact.ContactTitle}
        required={false}
        onChange={(value, res) => update('ContactTitle', value, res)}
      />

      <UserInput
        name="Contact title (En)"
        max={300}
        pattern=".{1,300}"
        invalid={invalids.ContactTitleEn}
        value={contact.ContactTitleEn}
        required={false}
        onChange={(value, res) => update('ContactTitleEn', value, res)}
      />

      <UserInput
        name="Phone number"
        max={300}
        pattern=".{1,300}"
        invalid={invalids.ContactPhoneNumber}
        value={contact.ContactPhoneNumber}
        required={true}
        onChange={(value, res) => update('ContactPhoneNumber', value, res)}
      />

      <UserInput
        name="Fax number"
        max={300}
        pattern=".{1,300}"
        invalid={invalids.ContactFaxNumber}
        value={contact.ContactFaxNumber}
        required={false}
        onChange={(value, res) => update('ContactFaxNumber', value, res)}
      />
      <UserInputs
        name="Contact reply mails"
        max={300}
        pattern=".{1,300}"
        invalid={invalids.ContactReplyMail}
        value={contact.ContactReplyMail}
        required={false}
        onChange={(value, index, res) =>
          updateArray('ContactReplyMail', value, index, res)
        }
      />
    </>
  )
}

export default Inputs
